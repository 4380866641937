<script>
import { mapState, mapActions } from 'vuex'

import { toastMixins } from '@/mixins'
import authService from '@/services/auth/auth'
import userService from '@/services/user/user'

export default {
  mixins: [toastMixins],
  props: {
    modeProp: String, // CREATE , UPDATE , READ
    variantProp: String,
    systemMenuAccessProp: Object
  },
  data() {
    return {
      moduleSelected: null,
      moduleOptions: [],
      moduleName: null,
      description: null,
      choiceSelected: 'userId',
      userSelected: null,
      userOptions: [],
      groupSelected: null,
      groupOptions: [],
      permissionsSelected: [],
      permissionChoices: [
        { key: 'canView', value: 'เข้าดู' },
        { key: 'canAddNew', value: 'เพิ่ม' },
        { key: 'canUpdate', value: 'แก้ไข' },
        { key: 'canDelete', value: 'ลบ' },
        { key: 'canPrint', value: 'พิมพ์' },
        { key: 'canApprove', value: 'อนุมัติ' },
        { key: 'canCancel', value: 'ยกเลิก' },
        { key: 'canEx1', value: 'อื่น ๆ 1' },
        { key: 'canEx2', value: 'อื่น ๆ 2' }
      ],
      setUpData: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      appLoading: state => state.appLoading,
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  watch: {
    moduleSelected() {
      if (this.moduleSelected && !this.setUpData) {
        const { moduleName, description } = this.moduleOptions.find(
          el => el.module === this.moduleSelected
        )
        this.moduleName = moduleName
        this.description = description
      }
    }
  },
  async created() {
    this.setAppLoading(true)
    await Promise.all([
      this.fetchModules(),
      this.fetchUserIds(),
      this.fetchGroupCodes()
    ])
    if (this.modeProp !== 'CREATE') {
      this.initializeData()
    }
    this.setAppLoading(false)
  },
  methods: {
    ...mapActions(['setAppLoading']),
    async fetchModules() {
      try {
        const res = await authService.getSystemMenuMasters({})
        this.moduleOptions = res.data.data.map(el => ({
          ...el,
          value: el.module,
          text: el.moduleName
        }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    async fetchUserIds() {
      try {
        const res = await userService.getUserLogonMasters({})
        this.userOptions = res.data.data.map(el => ({
          ...el,
          value: el.userId,
          text: el.userName
        }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    async fetchGroupCodes() {
      try {
        const res = await authService.getGroupLogonMasters({})
        this.groupOptions = res.data.data.map(el => ({
          ...el,
          value: el.groupCode,
          text: el.groupName
        }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    initializeData() {
      this.setUpData = true
      const {
        moduleId,
        moduleName,
        description,
        userIdGroupCode
      } = this.systemMenuAccessProp
      this.moduleSelected = moduleId
      this.moduleName = moduleName
      this.description = description
      const foundInUser = this.userOptions.find(
        el => el.userId === userIdGroupCode
      )
      const foundInGroup = this.groupOptions.find(
        el => el.groupCode === userIdGroupCode
      )
      if (foundInUser) {
        this.choiceSelected = 'userId'
        this.userSelected = userIdGroupCode
      } else if (foundInGroup) {
        this.choiceSelected = 'groupCode'
        this.groupSelected = userIdGroupCode
      }
      this.permissionsSelected = []
      this.permissionChoices.forEach(({ key }) => {
        if (this.systemMenuAccessProp[key]) {
          this.permissionsSelected.push(key)
        }
      })
      setTimeout(() => {
        this.setUpData = false
      }, this.defaultDelayMs)
    },
    async buttonClicked(type, payload = {}) {
      switch (type) {
        case 'PERMISSION':
          const { permission } = payload
          if (this.permissionsSelected.includes(permission)) {
            this.permissionsSelected = this.permissionsSelected.filter(
              el => el !== permission
            )
          } else {
            this.permissionsSelected.push(permission)
          }
          break
        case 'CONFIRM':
          this.setAppLoading(true)
          try {
            if (this.modeProp === 'CREATE') {
              await this.createNewSystemMenuAccess()
              this.$emit('closeModal', {
                type: 'CLOSE_WITH_SUCCESS',
                message: 'สร้างสิทธิ์การใช้งานใหม่สำเร็จ'
              })
            } else if (this.modeProp === 'UPDATE') {
              await this.updateSystemMenuAccess()
              this.$emit('closeModal', {
                type: 'CLOSE_WITH_SUCCESS',
                message: 'แก้ไขสิทธิ์การใช้งานเดิมสำเร็จ'
              })
            }
          } catch (err) {
            this.errMsg = err.response.data.thMessage
          }
          this.setAppLoading(false)
          break
        case 'CANCEL':
          this.$emit('closeModal', { type: 'CLOSE' })
          break
        default:
          break
      }
    },
    async createNewSystemMenuAccess() {
      const payload = {
        moduleId: this.moduleSelected,
        moduleName: this.moduleName,
        description: this.description,
        userIdGroupCode:
          this.choiceSelected === 'userId'
            ? this.userSelected
            : this.groupSelected,
        permissions: this.permissionsSelected
      }
      await authService.createSystemMenuAccess(payload)
    },
    async updateSystemMenuAccess() {
      const id = this.systemMenuAccessProp.id
      const body = {
        moduleId: this.moduleSelected,
        moduleName: this.moduleName,
        description: this.description,
        userIdGroupCode:
          this.choiceSelected === 'userId'
            ? this.userSelected
            : this.groupSelected,
        allowPermissions: this.permissionsSelected,
        notAllowPermissions: this.permissionChoices
          .map(p => p.key)
          .filter(p => !this.permissionsSelected.includes(p))
      }
      await authService.updateSystemMenuAccess(id, body)
    }
  }
}
</script>

<template>
  <div>
    <b-row>
      <!-- module -->
      <b-col cols="12" class="mb-3">
        <div>
          <label>ระบบงาน</label>
          <b-form-select
            v-model="moduleSelected"
            :options="moduleOptions"
            :disabled="appLoading || modeProp === 'READ'"
          ></b-form-select>
        </div>
        <div class="mt-3" v-if="!!moduleSelected">
          <label>ชื่อระบบงาน</label>
          <b-input
            v-model="moduleName"
            :disabled="appLoading || modeProp === 'READ'"
          ></b-input>
        </div>
        <div class="mt-3" v-if="!!moduleSelected">
          <label>คำอธิบาย</label>
          <b-input
            v-model="description"
            :disabled="appLoading || modeProp === 'READ'"
          ></b-input>
        </div>
      </b-col>

      <!-- userIdGroupCode -->
      <b-col cols="6" class="mb-3">
        <div>
          <div class="mb-2">
            <b-form-radio
              v-model="choiceSelected"
              name="ผู้ใช้งาน"
              value="userId"
              :disabled="appLoading || modeProp === 'READ'"
            >
              ผู้ใช้งาน
            </b-form-radio>
          </div>
          <div>
            <b-form-select
              v-model="userSelected"
              :options="userOptions"
              :disabled="
                appLoading ||
                  choiceSelected === 'groupCode' ||
                  modeProp === 'READ'
              "
            ></b-form-select>
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="mb-3">
        <div>
          <div class="mb-2">
            <b-form-radio
              v-model="choiceSelected"
              name="กลุ่มผู้ใช้งาน"
              value="groupCode"
              :disabled="appLoading || modeProp === 'READ'"
            >
              กลุ่มผู้ใช้งาน
            </b-form-radio>
          </div>
          <div>
            <b-form-select
              v-model="groupSelected"
              :options="groupOptions"
              :disabled="
                appLoading || choiceSelected === 'userId' || modeProp === 'READ'
              "
            ></b-form-select>
          </div>
        </div>
      </b-col>

      <!-- permission -->
      <b-col cols="12">
        <b-row>
          <b-col
            cols="4"
            v-for="choice in permissionChoices"
            :key="choice.key"
            class="m-0 p-2"
          >
            <b-button
              class="w-100"
              :variant="
                permissionsSelected.includes(choice.key)
                  ? 'primary'
                  : 'outline-primary'
              "
              @click="buttonClicked('PERMISSION', { permission: choice.key })"
              :disabled="appLoading || modeProp === 'READ'"
            >
              {{ choice.value }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr v-if="modeProp !== 'READ'" />

    <b-row align-h="end" v-if="modeProp !== 'READ'">
      <b-col class="col-3">
        <b-button
          class="w-100"
          :variant="variantProp"
          @click="buttonClicked('CONFIRM')"
          :disabled="appLoading"
        >
          <b-spinner v-if="appLoading" variant="light" small></b-spinner>
          <span v-else>บันทึก</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          :variant="`outline-${variantProp}`"
          @click="buttonClicked('CANCEL')"
          :disabled="appLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
