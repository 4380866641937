<script>
import { mapState, mapActions } from 'vuex'

import { toastMixins } from '@/mixins'
import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar },
  data() {
    return {
      sortBySelected: 'moduleId',
      sortByOptions: [
        { value: 'moduleId', text: 'รหัสงาน' },
        { value: 'moduleName', text: 'ชื่องาน' },
        { value: 'userIdGroupCode', text: 'ผู้ใช้งาน/กลุ่มผู้ใช้งาน' },
        { value: 'createdAt', text: 'สร้างเมื่อ' },
        { value: 'updatedAt', text: 'แก้ไขล่าสุดเมื่่อ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      searchBySelected: 'moduleId',
      searchByOptions: [{ value: 'moduleId', text: 'รหัสงาน' }],
      searchKeyword: '',
      searchKeywordSetTimer: null,
      tableFields: [
        {
          field: 'moduleId',
          title: 'รหัสงาน',
          align: 'left',
          width: 150
        },
        {
          field: 'moduleName',
          title: 'ชื่องาน',
          align: 'left',
          width: 150
        },
        {
          field: 'description',
          title: 'คำอธิบาย',
          align: 'left',
          width: 150
        },
        {
          field: 'userIdGroupCode',
          title: 'ผู้ใช้งาน/กลุ่มผู้ใช้งาน',
          align: 'left',
          width: 150
        },
        {
          field: 'canView',
          title: 'เข้าดู',
          align: 'center',
          width: 50
        },
        {
          field: 'canAddNew',
          title: 'เพิ่ม',
          align: 'center',
          width: 50
        },
        {
          field: 'canUpdate',
          title: 'แก้ไข',
          align: 'center',
          width: 50
        },
        {
          field: 'canDelete',
          title: 'ลบ',
          align: 'center',
          width: 50
        },
        {
          field: 'canPrint',
          title: 'พิมพ์',
          align: 'center',
          width: 50
        },
        {
          field: 'canApprove',
          title: 'อนุมัติ',
          align: 'center',
          width: 50
        },
        {
          field: 'canCancel',
          title: 'ยกเลิก',
          align: 'center',
          width: 50
        },
        {
          field: 'canEx1',
          title: 'อื่น ๆ 1',
          align: 'center',
          width: 50
        },
        {
          field: 'canEx2',
          title: 'อื่น ๆ 2',
          align: 'center',
          width: 50
        }
      ],
      systemMenuAccesses: [],
      systemMenuAccessSelected: null,
      readModalDisplay: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      appLoading: (state) => state.appLoading,
      defaultDelayMs: (state) => state.constants.defaultDelayMs,
      defaultKeywordTimerMs: (state) => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    sortBySelected() {
      this.fetchSystemMenuAccesses()
    },
    sortTypeSelected() {
      this.fetchSystemMenuAccesses()
    },
    searchBySelected() {
      this.fetchSystemMenuAccesses()
    },
    searchKeyword() {
      if (this.searchKeywordSetTimer) clearTimeout(this.searchKeywordSetTimer)

      this.searchKeywordSetTimer = setTimeout(() => {
        this.fetchSystemMenuAccesses()
      }, this.defaultKeywordTimerMs)
    }
  },
  async created() {
    await this.fetchSystemMenuAccesses()
  },
  methods: {
    ...mapActions(['setAppLoading']),
    async fetchSystemMenuAccesses() {
      this.setAppLoading(true)
      try {
        this.systemMenuAccessSelected = null

        const qsArray = []
        if (this.sortBySelected) qsArray.push(`sortBy=${this.sortBySelected}`)
        if (this.sortTypeSelected)
          qsArray.push(`sortType=${this.sortTypeSelected}`)
        // Not support searchBy yet
        // if (this.searchBySelected)
        //   qsArray.push(`searchBy=${this.searchBySelected}`)
        if (this.searchKeyword && this.searchKeyword.trim().length > 0)
          qsArray.push(`keyword=${this.searchKeyword.trim()}`)
        const qs = qsArray.join('&')

        const res = await authService.getSystemMenuAccesses({ qs })
        this.systemMenuAccesses = res.data.data
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.setAppLoading(false)
    },
    getSearchKeywordPlaceholder(searchBySelected) {
      return (
        this.searchByOptions.find((option) => option.value === searchBySelected)
          .text || ''
      )
    },
    rowClickedHandler(selectedSystemMenuAccess) {
      this.systemMenuAccessSelected = selectedSystemMenuAccess
    },
    rowDoubleClickedHandler(systemMenuAccess) {
      this.systemMenuAccessSelected = systemMenuAccess
      this.readModalDisplay = true
      setTimeout(() => {
        this.readModalDisplay = false
      }, this.defaultDelayMs / 5)
    },
    getRowCss(row) {
      if (this.systemMenuAccessSelected) {
        if (this.systemMenuAccessSelected.id === row.id) {
          return { backgroundColor: 'lightblue' }
        }
      }
      return null
    },
    useStyles(key) {
      switch (key) {
        case 'datagrid-style':
          return { height: '500px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="สิทธิ์การใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :systemMenuAccessProp="systemMenuAccessSelected"
          :readModalDisplayProp="readModalDisplay"
          @updateTable="fetchSystemMenuAccesses"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- ส่วนของ selection & search -->
    <b-row class="my-3 d-flex justify-content-center">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-by-selection">
              เรียงตาม
            </label>
            <b-form-select
              id="sort-by-selection"
              v-model="sortBySelected"
              :options="sortByOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-type-selection">
              เรียงแบบ
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="sortTypeSelected"
              :options="sortTypeOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <b-col cols="12" md="2">
            <!-- Not use yet -->
            <!-- <label class="text-white text-sm" for="sort-type-selection">
              ค้นหาโดย
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="searchBySelected"
              :options="searchByOptions"
              style="font-size: 0.9rem;"
            ></b-form-select> -->
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="search-input"> ค้นหา </label>
            <b-form-input
              id="search-input"
              v-model="searchKeyword"
              :placeholder="getSearchKeywordPlaceholder(this.searchBySelected)"
              style="font-size: 0.9rem"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ส่วนของ table -->
    <b-row class="d-flex flex-column align-items-center">
      <b-col cols="12" class="text-center">
        <div class="bg-light py-2">
          <h3>สิทธิ์การใช้งานในระบบ</h3>
        </div>
      </b-col>
      <b-col cols="12" class="text-center">
        <DataGrid
          :data="systemMenuAccesses"
          :rowCss="getRowCss"
          :style="useStyles('datagrid-style')"
          @rowClick="rowClickedHandler"
          @rowDblClick="rowDoubleClickedHandler"
        >
          <GridColumn
            v-for="field in tableFields"
            :key="field.field"
            :field="field.field"
            :title="field.title"
            :align="field.align"
            :width="field.width"
          >
            <template #cell="data" :field="field">
              <div
                v-if="field.field.startsWith('can')"
                class="h5 d-flex align-items-center justify-content-center m-0 p-0"
              >
                <b-icon
                  v-if="!!data.row[field.field]"
                  icon="check-square-fill"
                  variant="success"
                ></b-icon>
                <b-icon v-else icon="x" variant="danger"></b-icon>
              </div>
              <div v-else>
                <span>{{ data.row[field.field] }}</span>
              </div>
            </template>
          </GridColumn>
        </DataGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
