<script>
import CreateUpdateReadForm from './CreateUpdateReadForm.vue'

export default {
  components: { CreateUpdateReadForm },
  methods: {
    closeModalHandler(payload) {
      this.$emit('closeModal', payload)
    }
  }
}
</script>

<template>
  <create-update-read-form
    modeProp="CREATE"
    :variantProp="'success'"
    @closeModal="closeModalHandler"
  ></create-update-read-form>
</template>

<style></style>
