<script>
import { mapState, mapActions } from 'vuex'

import authService from '@/services/auth/auth'

export default {
  props: {
    systemMenuAccessProp: Object
  },
  computed: {
    ...mapState({
      appLoading: state => state.appLoading
    }),
    getTargetSystemMenuAccessInfo() {
      if (this.systemMenuAccessProp) {
        const {
          moduleId,
          moduleName,
          description,
          userIdGroupCode
        } = this.systemMenuAccessProp
        return [
          { key: 'moduleId', value: moduleId || '-', label: 'รหัสงาน' },
          { key: 'moduleName', value: moduleName || '-', label: 'ชื่องาน' },
          { key: 'description', value: description || '-', label: 'คำอธิบาย' },
          {
            key: 'userIdGroupCode',
            value: userIdGroupCode || '-',
            label: 'ผู้ใช้งาน/กลุ่มผู้ใช้งาน'
          }
        ]
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setAppLoading']),
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          if (this.systemMenuAccessProp) {
            this.deleteSystemMenuAccess(this.systemMenuAccessProp.id)
          }
          break
        case 'CANCEL':
          this.$emit('closeModal', {
            type: 'CLOSE'
          })
          break
        default:
          break
      }
    },
    async deleteSystemMenuAccess(accessId) {
      this.setAppLoading(true)
      try {
        await authService.deleteSystemMenuAccess(accessId)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบสิทธิ์การใช้งานสำเร็จ'
        })
      } catch (err) {
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: err.response.data.thMessage
        })
      }
      this.setAppLoading(false)
    }
  }
}
</script>

<template>
  <div v-if="systemMenuAccessProp">
    <b-row align-v="start">
      <b-col class="col-3">
        <b-icon
          icon="trash-fill"
          variant="danger"
          class="mt-2 icon-style"
        ></b-icon>
      </b-col>
      <b-col class="col-9">
        <h5>รายละเอียดของสิทธิ์การใช้งานที่จะลบ</h5>
        <div
          class="d-flex mt-3"
          v-for="info in getTargetSystemMenuAccessInfo"
          :key="info.key"
        >
          <span> {{ info.label }}: </span>
          <span class="ml-2 font-weight-bold">
            {{ info.value }}
          </span>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row align-h="end">
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="buttonClicked('CONFIRM')"
          :disabled="appLoading"
        >
          <b-spinner v-if="appLoading" variant="light" small></b-spinner>
          <span v-else>ลบ</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="outline-danger"
          @click="buttonClicked('CANCEL')"
          :disabled="appLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>
